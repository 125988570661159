export const seo = {
  url: 'transport/land',
  title: {
    pl: 'Transport Lądowy',
    en: 'Land Transport',
    ua: 'Наземний транспорт',
  },
  desc: {
    pl: `Transport lądowy to najpopularniejsza metoda transportu towarów na świecie. Poznaj rozwiązania dedykowane dla transportu ładunków drogą lądową.`,
    en: `Land transport is the most popular method of transporting goods in the world. Discover solutions dedicated to the transport of cargo by road.`,
    ua: `Наземний транспорт є найпопулярнішим способом перевезення вантажів у світі. Відкрийте для себе рішення, присвячені транспортуванню вантажів автомобільним транспортом.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'spedycja',
    'transport lądowy',
  ],
}

export const intro = {
  title: {
    pl: 'Transport Lądowy',
    en: 'Land Transport',
    ua: 'Наземний транспорт',
  },
  desc: {
    pl: `Transport lądowy to najpopularniejsza metoda transportu towarów na świecie. Poznaj rozwiązania dedykowane dla transportu ładunków drogą lądową.`,
    en: `Land transport is the most popular method of transporting goods in the world. Discover solutions dedicated to the transport of cargo by road.`,
    ua: `Наземний транспорт є найпопулярнішим способом перевезення вантажів у світі. Відкрийте для себе рішення, присвячені транспортуванню вантажів автомобільним транспортом.`,
  },
}

export const main = {
  title: {
    pl: 'Transport drogą lądową. <br/>Wybierz jedną spośród wielu usług transportu lądowego',
    en: 'Transport by land. <br/>Choose from a wide range of land transport services',
    ua: 'Транспорт автомобільним транспортом. <br/>Виберіть із різноманітних наземних транспортних послуг',
  },
  body: {
    pl: `
    <p>
    Najpopularniejszą metodą transportu lądowego jest transport drogowy drobnicowy, jak i całopojazdowy. Odpowiada on za około <strong>80% wszystkich przewożonych ładunków</strong>. Coraz popularniejszym rodzajem jest również transport intermodalny.
    </p>
    <p>
    W Omida Sea And Air S.A. posiadamy rozbudowaną ofertę transportu lądowego. Organizujemy transport drogowy i kolejowy. W naszej ofercie znajduje się również własny serwis drobnicowy z Chin do Polski oraz logistyka magazynowa.
    </p>
    `,
    en: `
    <p>
    The most popular method of land transport is groupage and full truckload road transport. It is responsible for approximately <strong>80% of all cargo transported</strong>. Intermodal transport is also an increasingly popular type.
    </p>
    <p>
    At Omida Sea And Air S.A. we have an extensive offer of land transport. We organize road and rail transport. Our offer also includes our own groupage service from China to Poland and warehouse logistics.
    </p>
    `,
    ua: `
    <p>
    Найпопулярнішим видом наземного транспорту є збірні та повні автомобільні перевезення. На нього припадає близько 80% усіх перевезених вантажів. Інтермодальні перевезення також стають все більш популярними.
    </p>
    <p>
    Компанія Omida Sea And Air S.A. пропонує широку пропозицію наземного транспорту. Організовуємо автомобільні та залізничні перевезення. Наша пропозиція також включає власні послуги збірних перевезень з Китаю до Польщі та складську логістику.
    </p>
    `,
  },
}
